// MAXLOAD
export const _getMaxload = () => import('./cart/acd-max-load');
export const _acdCombinedOrdersList = () => import('./cart/acd-get-combined-orders');

// CART
export const _getConvertItems = () => import('./cart/acd-convert-items');
export const _acdCartGop = () => import('./cart/acd-cart-gop');

// SAVED CART
export const _saveCartEbs = () => import('./cart/acd-save-cart-ebs');
export const acdCartOrdersList = () => import('./cart/acd-get-cart-list');

// ORDER TRACKING
export const _getOrdersList = () => import('./order/acd-orders-list');
export const _getOrderShipments = () => import('./order/acd-order-shipments');
export const _getOrderTracking = () => import('./order/acd-order-tracking');
export const _getOrderDetails = () => import('./order/acd-order-details');
export const _getLineItem = () => import('./order/acd-line-item');

// PRODUCST
export const _acdLocationInventories = () => import('./product/acd-location-inventories');

// COMMON
export const _updateEntity = () => import('./common/cc-update');
export const _getEntity = () => import('./common/cc-get');
